<template>
  <div class="setting-client-passage">
    <div class="box-setting-header">
      <div class="title ml-4 mt-2">Gestion des clients passager</div>
      <div>
        <b-button
          v-if="checkPermission('GACP')"
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addClientModal
        >
          <font-awesome-icon icon="plus" /> Ajouter un client passager
        </b-button>
      </div>
    </div>
    <div class="ligne-box-setting-header"></div>

    <div class="box-all-filter mb-2">
      <div>
        <div v-if="getClientlsLoading" class="chargement-message-calender ml-2">
          Chargement en cours ...
        </div>
        <div class="error-message">
          <div v-if="getClientIsError" class="error">
            <ul v-if="Array.isArray(getClientIsError)">
              <li v-for="(e, index) in getClientIsError" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getClientIsError }}</div>
          </div>
        </div>
      </div>
      <div class="box-end-position">
        <search
          v-if="checkPermission('GRCP')"
          :value="searchValue"
          @changeSearchValue="changeSearchValue"
          classToUse="search-style"
        />
      </div>
    </div>
    <div class="table-rapport-style w-100-p">
      <b-table
        show-empty
        id="my-table"
        class="custom-table-style table-passages-params"
        :items="getListClientPassages"
        :fields="computedFields"
        bordered
        sticky-header
        hover
        head-variant="light"
        empty-text="Il n'y a aucun enregistrement à afficher"
        tbody-tr-class="ligneEnAttend"
      >
        <template v-slot:cell(Actions)="data">
          <b-button
            v-if="checkPermission('GSCP')"
            class="button-warning-style mr-2"
            size="sm"
            variant="danger"
            @click.prevent.stop="showDeleteClient(data.item)"
            title="Supprimer"
          >
            <font-awesome-icon icon="trash" />
          </b-button>
          <b-button
            v-if="checkPermission('GMCP')"
            class="button-update-style mr-2"
            size="sm"
            variant="success"
            @click.prevent.stop="showUpdateClient(data.item)"
            title="Modifier"
          >
            <font-awesome-icon icon="pencil-alt" />
          </b-button>
          <b-button
            v-if="checkPermission('GTCP')"
            class="button-transfert-style"
            size="sm"
            @click.prevent.stop="showTransfertClient(data.item)"
            title="Transfert du client vers la gestion des sociétés"
          >
            <font-awesome-icon icon="file-export"
          /></b-button>
        </template>
      </b-table>
      <div class="footer-style">
        <b-pagination
          v-model="page"
          :total-rows="getTotalRowsPassages"
          :per-page="perPage"
          aria-controls="my-table"
          pills
          align="center"
          size="sm"
          @change="pagination"
          class="pagination-style"
        ></b-pagination>

        <div class="per-page-element-style">
          <div class="box-label-champ">
            <div class="label-box-style">
              <span class="title-tabel">Eléments par page</span>
            </div>
          </div>
          <b-form-select
            v-model="perPage"
            :options="perPageList"
            @change="changePerPage"
            class="b-form-select-new-style bg-select"
          ></b-form-select>
        </div>
      </div>
    </div>

    <!-- ADD MODAL CLIENT  -->
    <b-modal
      ref="addClientModal"
      id="addClientModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap  cutsom-modal-bootstrap-filiale-th"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Ajouter un client passager</div>
        <div
          class="iconClose"
          title="Fermer"
          @click.prevent="hideModal('addClientModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent.stop="handleSubmitClient"
            class="form-modal-custom-style"
          >
            <b-form-group
              label="Nom de client *"
              label-for="Nom vendeur"
              class="input-modal-champ col-3 mb-0 pl-0"
            >
              <b-form-input
                id="siren_value_ach"
                v-model="configurationClientToAdd.name"
                autocomplete="off"
                required
              ></b-form-input>
            </b-form-group>
            <div class="row mb-0 mt-3">
              <b-form-group class="input-modal-champ col-3 pr-0 mb-0">
                <b-form-select
                  id="num_tva_siren_acheteur"
                  v-model="configurationClientToAdd.siren_tva"
                  :options="num_tva_siren"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                class="input-modal-champ col-9 mb-0"
                v-if="
                  configurationClientToAdd &&
                    configurationClientToAdd.siren_tva === 'Numéro TVA'
                "
              >
                <b-form-input
                  v-if="configurationClientToAdd"
                  id="siren_value_acheteur "
                  v-model="configurationClientToAdd.tva_value"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="input-modal-champ col-9 mb-0"
                v-if="
                  configurationClientToAdd &&
                    configurationClientToAdd.siren_tva === 'SIREN'
                "
              >
                <b-form-input
                  id="siren_value_ach"
                  v-model="configurationClientToAdd.siren_value"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
              {{ configurationClientToAdd.siren_tva }}
            </div>
            <b-form-group
              label="N° et nom de rue *"
              label-for="N° et nom de rue"
              class="input-modal-champ mb-0"
            >
              <b-form-textarea
                id="adress_acheteur"
                v-model="configurationClientToAdd.adresse"
                autocomplete="off"
                required
              ></b-form-textarea>
            </b-form-group>
            <div class="row m-0">
              <b-form-group
                label="Code Postal *"
                label-for="Code Postal"
                class="input-modal-champ col-4 mb-0 pl-0"
              >
                <b-form-input
                  id="code_postal_acheteur"
                  v-model="configurationClientToAdd.code_postal"
                  autocomplete="off"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Ville *"
                label-for="Ville"
                class="input-modal-champ p-0 col-4 m-0"
              >
                <b-form-input
                  id="ville_acheteur"
                  v-model="configurationClientToAdd.ville"
                  autocomplete="off"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Pays *"
                label-for="Pays"
                class="input-modal-champ p-0 col-4 m-0"
              >
                <b-form-select
                  id="pays"
                  v-model="configurationClientToAdd.pays"
                  :options="uniqueArrayOfCountries"
                  class="b-form-select-raduis"
                  text-field="nom"
                  value-field="nom"
                  required
                ></b-form-select>
              </b-form-group>
            </div>
            <b-form-group
              label="Description additionnelle"
              label-for="description_additionnelle"
              class="input-modal-champ pr-1 pl-1 m-0"
            >
              <b-form-textarea
                id="description"
                rows="2"
                v-model="configurationClientToAdd.description"
              ></b-form-textarea>
            </b-form-group>
            <div class="row m-0">
              <b-form-group
                label="Siret"
                label-for="iban"
                class="input-modal-champ col-6 p-0 m-0"
              >
                <b-form-input
                  id="siret_acheteur"
                  v-model="configurationClientToAdd.siret"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Adresse Email *"
                label-for="Adress_email"
                class="input-modal-champ p-0 m-0"
                :class="mails.length != 0 ? 'col-5' : 'col-6'"
              >
                <b-form-input
                  type="email"
                  id="email"
                  v-model="configurationClientToAdd.email"
                  @blur="checkFilialeMail(configurationClientToAdd.email)"
                  autocomplete="off"
                  required
                ></b-form-input>
              </b-form-group>
              <div
                class="col-1 "
                style="padding-top: 33px;"
                v-if="mails && mails.length"
              >
                <font-awesome-icon
                  id="popover-1-email"
                  v-show="mails && mails.length"
                  icon="info"
                  class="
          info-icon-commercial-lot
          content-tooltip-style
          w-25
        "
                  style="color : red ; cursor : pointer ; "
                />

                <b-popover
                  target="popover-1-email"
                  triggers="focus"
                  custom-class="avoir-tooltip custom-z-index-popover custem-popover-passager"
                >
                  <b-table
                    show-empty
                    id="my-table"
                    class="custom-table-style
custom-table-passage table-header"
                    :items="mails"
                    :fields="[{ key: 'name', label: 'Nom société' }]"
                    bordered
                    sticky-header
                    hover
                    responsive
                    head-variant="light"
                    empty-text="Il
n'y a aucun enregistrement à afficher"
                  >
                    <template v-slot:cell(name)="data">
                      {{ data.item.name }}
                    </template>
                  </b-table>
                </b-popover>
              </div>
            </div>
            <div class="row m-0">
              <b-form-group
                label="Téléphone"
                label-for="tel"
                class="input-modal-champ col-6 p-0 m-0"
              >
                <b-form-input
                  id="tel_portable"
                  v-model="configurationClientToAdd.telephone"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Téléphone portable *"
                label-for="tel_portable"
                class="input-modal-champ p-0 col-6 m-0"
              >
                <b-form-input
                  id="tel_portable"
                  v-model="configurationClientToAdd.telephone_portable"
                  autocomplete="off"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="actionModel mt-2">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style mt-3">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <!-- END ADD MODAL CLIENT -->
    <!-- UPDATE MODAL CLIENT -->
    <b-modal
      ref="updateClientModal"
      id="updateClientModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap  cutsom-modal-bootstrap-filiale-th"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier un client passager</div>
        <div
          class="iconClose"
          title="Fermer"
          @click.prevent="hideModal('updateClientModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent.stop="handleUpdateClient"
            class="form-modal-custom-style"
          >
            <b-form-group
              label="Nom de client *"
              label-for="Nom vendeur"
              class="input-modal-champ p-0 col-6 m-0"
            >
              <b-form-input
                id="siren_value_ach"
                v-model="configurationClientToUpdate.name"
                autocomplete="off"
                required
              ></b-form-input>
            </b-form-group>
            <div class="row mb-0 mt-3">
              <b-form-group class="input-modal-champ col-3 pr-0 mb-0">
                <b-form-select
                  id="num_tva_siren_acheteur"
                  v-model="configurationClientToUpdate.siren_tva"
                  :options="num_tva_siren"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                class="input-modal-champ col-9 mb-0"
                v-if="
                  configurationClientToUpdate &&
                    configurationClientToUpdate.siren_tva === 'Numéro TVA'
                "
              >
                <b-form-input
                  v-if="configurationClientToUpdate"
                  id="siren_value_acheteur "
                  v-model="configurationClientToUpdate.tva_value"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="input-modal-champ col-9 mb-0"
                v-if="
                  configurationClientToUpdate &&
                    configurationClientToUpdate.siren_tva === 'SIREN'
                "
              >
                <b-form-input
                  id="siren_value_ach"
                  v-model="configurationClientToUpdate.siren_value"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </div>
            <b-form-group
              label="N° et nom de rue *"
              label-for="N° et nom de rue"
              class="input-modal-champ mb-0"
            >
              <b-form-textarea
                id="adress_acheteur"
                v-model="configurationClientToUpdate.adresse"
                autocomplete="off"
                required
              ></b-form-textarea>
            </b-form-group>
            <div class="row m-0">
              <b-form-group
                label="Code Postal *"
                label-for="Code Postal"
                class="input-modal-champ p-0 col-4 m-0"
              >
                <b-form-input
                  id="code_postal_acheteur"
                  v-model="configurationClientToUpdate.code_postal"
                  autocomplete="off"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Ville *"
                label-for="Ville"
                class="input-modal-champ p-0 col-4 m-0"
              >
                <b-form-input
                  id="ville_acheteur"
                  v-model="configurationClientToUpdate.ville"
                  autocomplete="off"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Pays *"
                label-for="Pays"
                class="input-modal-champ p-0 col-4 m-0"
              >
                <b-form-select
                  id="pays"
                  v-model="configurationClientToUpdate.pays"
                  :options="uniqueArrayOfCountries"
                  class="b-form-select-raduis"
                  text-field="nom"
                  value-field="nom"
                  required
                ></b-form-select>
              </b-form-group>
            </div>
            <b-form-group
              label="Description additionnelle"
              label-for="description_additionnelle"
              class="input-modal-champ pr-1 pl-1 m-0"
            >
              <b-form-textarea
                id="description"
                rows="2"
                v-model="configurationClientToUpdate.description"
              ></b-form-textarea>
            </b-form-group>
            <div class="row m-0">
              <b-form-group
                label="Siret"
                label-for="iban"
                class="input-modal-champ p-0 col-6 m-0"
              >
                <b-form-input
                  id="siret_acheteur"
                  v-model="configurationClientToUpdate.siret"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Adresse Email *"
                label-for="Adress_email"
                class="input-modal-champ p-0 m-0"
                :class="mails.length != 0 ? 'col-5' : 'col-6'"
              >
                <b-form-input
                  type="email"
                  id="email"
                  v-model="configurationClientToUpdate.email"
                  autocomplete="off"
                  @blur="checkFilialeMail(configurationClientToUpdate.email)"
                  required
                ></b-form-input>
              </b-form-group>
              <div
                class="col-1 "
                style="padding-top: 33px;"
                v-if="mails && mails.length"
              >
                <font-awesome-icon
                  :id="'popover-1-' + configurationClientToUpdate.id"
                  v-show="mails && mails.length"
                  icon="info"
                  class="
          info-icon-commercial-lot
          content-tooltip-style
          w-25
        "
                  style="color : red ; cursor : pointer ; "
                />

                <b-popover
                  :target="'popover-1-' + configurationClientToUpdate.id"
                  triggers="focus"
                  custom-class="avoir-tooltip custom-z-index-popover custem-popover-passager"
                >
                  <b-table
                    show-empty
                    id="my-table"
                    class="custom-table-style
custom-table-passage table-header"
                    :items="mails"
                    :fields="[{ key: 'name', label: 'Nom société' }]"
                    bordered
                    sticky-header
                    hover
                    responsive
                    head-variant="light"
                    empty-text="Il
n'y a aucun enregistrement à afficher"
                  >
                    <template v-slot:cell(name)="data">
                      {{ data.item.name }}
                    </template>
                  </b-table>
                </b-popover>
              </div>
            </div>
            <div class="row m-0">
              <b-form-group
                label="Téléphone"
                label-for="tel"
                class="input-modal-champ col-6 p-0 m-0"
              >
                <b-form-input
                  id="tel_portable"
                  v-model="configurationClientToUpdate.telephone"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Téléphone portable *"
                label-for="tel_portable"
                class="input-modal-champ p-0 col-6 m-0"
              >
                <b-form-input
                  id="tel_portable"
                  v-model="configurationClientToUpdate.telephone_portable"
                  autocomplete="off"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="actionModel mt-2">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style mt-3">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <!-- END UPDATE MODAL CLIENT -->
    <!-- DELETED MODAL CLIENT -->
    <b-modal
      ref="deletedClientModal"
      id="deletedClientModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer un client passager</div>
        <div class="iconClose" @click.prevent="hideModal('deletedClientModal')">
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form
        @submit.prevent="handleDeleteConfClient"
        class="form-modal-custom-style"
      >
        <div class="mt-4 ml-3">
          <p>
            Êtes-vous sur de vouloir supprimer cette client
            <span class="fontBold">{{
              configurationClientToDeleted
                ? configurationClientToDeleted.name
                : ''
            }}</span>
            ?
          </p>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="'error' + index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <b-button type="submit" class="button-valide-style mt-1">
            <span>
              Valider
              <div v-if="loading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- END DELETED MODAL CLIENT -->
    <!-- ADD TRANSFERT CLIENT VERS LA GESTIONS DES SOCIETES MODAL -->
    <b-modal
      ref="transfetClientModal"
      id="transfetClientModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap  cutsom-modal-bootstrap-transfertClient"
    >
      <div class="hader mb-2">
        <div class="titleSetting">
          Transfert du client vers la gestion des sociétés
        </div>
        <div
          class="iconClose"
          @click.prevent="hideModal('transfetClientModal')"
          title="Fermer"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent.stop="handleSubmitTranssfertClient"
            class="form-modal-custom-style"
          >
            <div class="ModelCol checkbox-style-type mt-3 align-items-center">
              <div class="col-8">
                <b-form-group
                  label="Type de société*"
                  label-for="type_societe"
                  class="input-modal-champ col-4"
                >
                  <b-form-select
                    autocomplete="off"
                    id="type-societe"
                    v-model="configurationClientToTransfertClient.type"
                    disabled
                    :options="optionsTypeSociete"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
              </div>
              <b-form-checkbox
                v-model="configurationClientToTransfertClient.in_configs"
                name="checkbox-1"
                :value="'oui'"
                :unchecked-value="'non'"
                class="check-th mt-4"
              >
                Configure une société
              </b-form-checkbox>
            </div>
            <div
              class="ModelCol checkbox-style-type mt-3 align-items-center"
              :class="{
                'justify-space-around':
                  configurationClientToTransfertClient.is_societe_support ==
                  true
              }"
            >
              <b-form-checkbox
                v-model="
                  configurationClientToTransfertClient.is_societe_support
                "
                @change="onChangeSupport"
                name="checkbox-1"
                :value="true"
                :unchecked-value="false"
                class="check-th"
              >
                Support
              </b-form-checkbox>
              <b-form-group
                v-if="
                  configurationClientToTransfertClient.is_societe_support ==
                    true
                "
                label="Type de société support"
                label-for="filiale-type_societe-support"
                class="input-modal-champ mt-1 col-6"
              >
                <b-form-select
                  autocomplete="off"
                  id="filiale-type_societe-support"
                  v-model="
                    configurationClientToTransfertClient.type_societe_support
                  "
                  required
                  :options="getTypeSocieteSupportTh"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="ModelCol mr-3">
              <b-form-group
                label="Nom de société *"
                label-for="filiale-name"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="filiale-name"
                  v-model="configurationClientToTransfertClient.name"
                  required
                  v-on:keydown.13="my_method($event)"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                label="Type de société"
                label-for="filiale-name"
                class="input-modal-champ mb-0"
              >
                <b-form-select
                  autocomplete="off"
                  id="filiale-name"
                  v-model="configurationClientToTransfertClient.type_societe"
                  :options="getTypeSociete"
                  value-field="type"
                  text-field="type"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group label="Couleur" class="nput-modal-champ col-2 pl-0">
                <v-input-colorpicker
                  colorpick-eyedropper-active="false"
                  class="color_picker_width ml-2"
                  v-model="configurationClientToTransfertClient.couleur"
                />
              </b-form-group>
            </div>

            <b-form-group
              label="Adresse *"
              label-for="filiale-adresse"
              class="input-modal-champ mb-0"
            >
              <b-form-textarea
                autocomplete="off"
                id="filiale-adresse"
                v-model="configurationClientToTransfertClient.adresse"
                required
                v-on:keydown.13="my_method($event)"
              >
              </b-form-textarea>
            </b-form-group>
            <div class="ModelCol mr-3">
              <!-- START -->
              <b-form-group
                label="Jour début exercice"
                label-for="day-begin-exercie"
                class="input-modal-champ mb-0"
              >
                <b-form-select
                  required
                  autocomplete="off"
                  id="day-begin-exercie"
                  v-model="configurationClientToTransfertClient.start_day"
                  :options="
                    computedDaysOfMonth(
                      configurationClientToTransfertClient.start_month
                    )
                  "
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Mois début exercice"
                label-for="month-begin-exercice"
                class="input-modal-champ mb-0"
              >
                <b-form-select
                  required
                  autocomplete="off"
                  id="month-begin-exercice"
                  v-model="configurationClientToTransfertClient.start_month"
                  :options="month_list"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Année début exercice"
                label-for="year-begin-exercice"
                class="input-modal-champ mb-0"
              >
                <b-form-select
                  required
                  autocomplete="off"
                  id="year-begin-exercice"
                  v-model="configurationClientToTransfertClient.start_year"
                  :options="computedListeYear"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <!-- END -->
              <b-form-group
                label="Jour fin exercice"
                label-for="day-end-exercice"
                class="input-modal-champ mb-0"
              >
                <b-form-select
                  required
                  autocomplete="off"
                  id="day-end-exercice"
                  v-model="configurationClientToTransfertClient.end_day"
                  :options="
                    computedDaysOfMonth(
                      configurationClientToTransfertClient.end_month
                    )
                  "
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Mois fin exercice"
                label-for="month-end-exercice"
                class="input-modal-champ mb-0"
              >
                <b-form-select
                  required
                  autocomplete="off"
                  id="month-end-exercice"
                  v-model="configurationClientToTransfertClient.end_month"
                  :options="month_list"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Année fin exercice"
                label-for="year-end-exercice"
                class="input-modal-champ mb-0"
              >
                <b-form-select
                  required
                  autocomplete="off"
                  id="year-end-exercice"
                  v-model="configurationClientToTransfertClient.end_year"
                  :options="computedListeYear"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="ModelCol mr-3">
              <b-form-group
                label="Type de footer"
                label-for="filiale-type-footer"
                class="input-modal-champ mb-0"
              >
                <b-form-select
                  autocomplete="off"
                  id="filiale-type-footer"
                  v-model="configurationClientToTransfertClient.footer_type"
                  :options="['text', 'image']"
                  class="b-form-select-raduis"
                  @change="configurationClientToTransfertClient.footer = null"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                v-if="
                  configurationClientToTransfertClient.footer_type == 'text'
                "
                label=" "
                label-for="filiale-footer"
                class="input-modal-champ mb-0"
              >
                <b-form-textarea
                  autocomplete="off"
                  id="filiale-footer"
                  v-model="configurationClientToTransfertClient.footer"
                  required
                >
                </b-form-textarea>
              </b-form-group>
              <b-form-group
                v-if="
                  configurationClientToTransfertClient.footer_type == 'image'
                "
                label=" "
                label-for="filiale-footer "
                class="mt-2"
              >
                <b-form-file
                  v-model="configurationClientToTransfertClient.footer"
                  ref="file-type"
                  placeholder="Sélectionné une image"
                  drop-placeholder="Drop file here..."
                  required
                >
                </b-form-file
              ></b-form-group>
            </div>
            <div class="ModelCol mr-3">
              <b-form-group
                :label="
                  'Code postal' +
                    requiredP(configurationClientToTransfertClient)
                "
                label-for="filiale-code_postal"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="filiale-code_postal"
                  v-model="configurationClientToTransfertClient.code_postal"
                  v-on:keydown.13="my_method($event)"
                  required
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                :label="
                  'Ville' + requiredP(configurationClientToTransfertClient)
                "
                label-for="filiale-ville"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="filiale-ville"
                  v-model="configurationClientToTransfertClient.ville"
                  v-on:keydown.13="my_method($event)"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="ModelCol mr-3">
              <b-form-group
                :label="'Email * '"
                label-for="filiale-email"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="filiale-email"
                  v-model="configurationClientToTransfertClient.email"
                  required
                  type="email"
                  v-on:keydown.13="my_method($event)"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="
                  'Abréviation' +
                    requiredP(configurationClientToTransfertClient)
                "
                label-for="abr"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="filiale-email"
                  v-model="configurationClientToTransfertClient.abreviation"
                  v-on:keydown.13="my_method($event)"
                  required
                >
                </b-form-input>
              </b-form-group>
            </div>

            <div class="ModelCol mr-3">
              <b-form-group
                label="Siren"
                label-for="filiale-siren_tva"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="filiale-siren_tva"
                  v-model="configurationClientToTransfertClient.siren_value"
                  v-on:keydown.13="my_method($event)"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                label="Tva "
                label-for="filiale-siren_tva_value"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="filiale-siren_tva_value"
                  v-model="configurationClientToTransfertClient.tva_value"
                  v-on:keydown.13="my_method($event)"
                >
                </b-form-input>
              </b-form-group>
            </div>

            <div class="ModelCol mr-3">
              <b-form-group
                label="Capital "
                label-for="filiale-capital_tva"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="filiale-capital_tva"
                  v-model="configurationClientToTransfertClient.capital"
                  v-on:keydown.13="my_method($event)"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                label="RCS "
                label-for="filiale-rcs_tva_value"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="filiale-rcs_tva_value"
                  v-model="configurationClientToTransfertClient.rcs"
                  v-on:keydown.13="my_method($event)"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <div class="ModelCol mr-3">
              <b-form-group
                label="Site internet"
                label-for="filiale-site_internet"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="filiale-site_internet"
                  v-model="configurationClientToTransfertClient.site_internet"
                  v-on:keydown.13="my_method($event)"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                label="Pays"
                label-for="filiale-pays"
                class="input-modal-champ mb-0"
              >
                <b-form-select
                  autocomplete="off"
                  id="filiale-pays"
                  v-model="configurationClientToTransfertClient.pays"
                  :options="uniqueArrayOfCountries"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="ModelCol mr-3">
              <b-form-group
                label="Siret"
                label-for="filiale-siret"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="filiale-siret"
                  v-model="configurationClientToTransfertClient.siret"
                  v-on:keydown.13="my_method($event)"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Fax"
                label-for="filiale-fax"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="filiale-fax"
                  v-model="configurationClientToTransfertClient.fax"
                  v-on:keydown.13="my_method($event)"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="ModelCol mr-3">
              <b-form-group
                label="Type Règlement"
                label-for="type-reglement"
                class="mr-2"
              >
                <b-form-select
                  v-model="configurationClientToTransfertClient.payment_type"
                  id="type-reglement"
                  :options="computedTypeReglement"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Conditions du paiement"
                label-for="condition"
                class="mr-2"
              >
                <b-form-select
                  v-model="
                    configurationClientToTransfertClient.payment_condition
                  "
                  id="condition"
                  :options="computedConditionPaiement"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="ModelCol mr-3">
              <b-form-group
                label="Téléphone"
                label-for="filiale-telephone"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="filiale-telephone"
                  v-model="configurationClientToTransfertClient.telephone"
                  type="number"
                  v-on:keydown.13="my_method($event)"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Téléphone portable"
                label-for="filiale-telephone_portable"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="filiale-telephone_portable"
                  v-model="
                    configurationClientToTransfertClient.telephone_portable
                  "
                  type="number"
                  v-on:keydown.13="my_method($event)"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="ModelCol mr-3">
              <b-form-group
                label="Description"
                label-for="filiale-description"
                class="input-modal-champ mb-0"
              >
                <b-form-textarea
                  autocomplete="off"
                  id="filiale-description"
                  v-model="configurationClientToTransfertClient.description"
                >
                </b-form-textarea>
              </b-form-group>
            </div>
            <template
              v-if="
                configurationClientToTransfertClient.type !== 'master filiale'
              "
            >
              <b-form-group
                label="Master filiales"
                label-for="filiale-swift"
                class="input-modal-champ mb-0"
              >
                <div class="rib_block">
                  <div
                    class="button-add-prop-filiale-th"
                    v-if="computedGetLastDateInList !== null"
                  >
                    <b-button
                      variant="success"
                      class="bien-add-button ml-2"
                      @click="addNewMasterFilial('add')"
                    >
                      <font-awesome-icon icon="plus" /> Ajouter un Master
                      Filiale
                    </b-button>
                  </div>
                  <b-row
                    class="mb-1"
                    v-for="(master,
                    index) in configurationClientToTransfertClient.masterFiliales"
                    :key="index"
                  >
                    <!-- SWIFT && IBAN  -->
                    <b-col col xs="3" sm="3" md="3">
                      <b-form-group
                        label="Nom master filiale *"
                        label-for="iban-template-input"
                        class="input-modal-champ"
                      >
                        <b-form-select
                          autocomplete="off"
                          id="iban-template-input"
                          v-model="master.master_id"
                          :options="computedgetMasterFilialsList"
                          text-field="name"
                          value-field="master_id"
                          class="b-form-select-raduis"
                          :disabled="index > 0"
                          required
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col col xs="4" sm="4" md="4">
                      <b-form-group
                        label="Date début"
                        label-for="fin"
                        class="input-modal-champ mb-0 pl-0"
                      >
                        <date-picker
                          value-type="format"
                          type="date"
                          required
                          v-model="master.date_debut"
                          :clear-button="true"
                          :disabled-date="
                            date => disabledStartDate(date, index)
                          "
                          :disabled="index > 0"
                        ></date-picker>
                      </b-form-group>
                    </b-col>
                    <b-col col xs="4" sm="4" md="4">
                      <b-form-group
                        label="Date fin"
                        label-for="fin"
                        class="input-modal-champ mb-0"
                      >
                        <date-picker
                          value-type="format"
                          type="date"
                          v-model="master.date_fin"
                          :clear-button="true"
                          :disabled-date="date => disabledEndDate(date, index)"
                          :disabled="index > 0"
                        ></date-picker>
                      </b-form-group>
                    </b-col>
                    <b-col col xs="1" sm="1" md="1">
                      <font-awesome-icon
                        icon="times"
                        class="time-icon-style"
                        @click="deleteMasterFilial(index, 'add')"
                      />
                    </b-col>
                  </b-row>
                </div>
              </b-form-group>
            </template>
            <b-form-group
              label="Support"
              label-for="filiale-responsable"
              class="vue-select-modal-champ"
            >
              <multiselect
                v-model="configurationClientToTransfertClient.responsables"
                :options="computedChangeResponsable"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                label="full_name"
                track-by="id"
                :preselect-first="false"
                class="select-vue-component-style mb-4"
                :showLabels="false"
              >
                <span slot="noResult">Aucune responsable trouvé.</span>
                <span slot="noOptions">Aucune responsable trouvé.</span>
              </multiselect>

              <b-form-group
                label="Logo Filiale"
                label-for="file"
                class="input-modal-champ-file mb-0"
              >
              </b-form-group>
              <div class="body-box-rapport body-box-setting">
                <div class="doc-list mt-1 box-upload">
                  <div class="content-files">
                    <b-form-file
                      v-model="configurationClientToTransfertClient.image"
                      placeholder=""
                      drop-placeholder=""
                      accept="image/*"
                      @change="previewImage"
                    ></b-form-file>
                  </div>
                </div>
              </div>
            </b-form-group>
            <div
              class="ModelCol checkbox-style-type mt-3 align-items-center"
              :class="{
                'justify-space-around':
                  configurationClientToTransfertClient.volant == true
              }"
            >
              <b-form-checkbox
                v-model="configurationClientToTransfertClient.volant"
                name="checkbox-1"
                :value="true"
                :unchecked-value="false"
                class="check-th"
              >
                VOLANT
              </b-form-checkbox>
              <b-form-group
                v-if="configurationClientToTransfertClient.volant == true"
                label="Responsable de dépôt"
                label-for="depot_id_volant"
                class="input-modal-champ  col-9"
              >
                <b-form-input
                  autocomplete="off"
                  id="depot_id_volant"
                  v-model="
                    configurationClientToTransfertClient.responsable_depot
                  "
                  v-on:keydown.13="my_method($event)"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <b-form-group
              v-if="configurationClientToTransfertClient.volant == false"
              label="Dépôt"
              label-for="filiale-depots"
              class="vue-select-modal-champ"
            >
              <multiselect
                v-model="configurationClientToTransfertClient.depots"
                :options="computedGetDepotsTh"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                label="depot"
                track-by="id"
                :preselect-first="false"
                class="select-vue-component-style mb-4"
                :showLabels="false"
              >
                <span slot="noResult">Aucune dépot trouvé.</span>
                <span slot="noOptions">Aucune dépot trouvé.</span>
              </multiselect>
            </b-form-group>
            <div v-show="configurationClientToTransfertClient.volant == false">
              <div
                class="ModelCol"
                v-for="depot in configurationClientToTransfertClient.depots"
                :key="'depot' + depot.id"
              >
                <span class="labes-depot-name">{{ depot.depot }}</span>
                <b-form-group
                  label="Responsable de dépôt"
                  label-for="depot_id "
                  class="input-modal-champ mt-1"
                >
                  <b-form-input
                    autocomplete="off"
                    id="depot_id"
                    v-model="depot.responsable_depot"
                    v-on:keydown.13="my_method($event)"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="body-box-rapport body-box-setting">
                <div class="doc-list mt-1 box-upload">
                  <div class="form-type">
                    <div class="form-groupe">
                      <div class="content-files">
                        <b-form-file
                          v-model="configurationClientToTransfertClient.files"
                          ref="file-type"
                          :required="false"
                          placeholder="Aucun fichier selectionné"
                          drop-placeholder="Drop file here..."
                          multiple
                        >
                        </b-form-file>
                      </div>

                      <div
                        class="file-header-titles mt-3"
                        v-if="
                          configurationClientToTransfertClient.files &&
                            configurationClientToTransfertClient.files.length
                        "
                      >
                        <div class="file-name-title">Fichiers</div>
                        <div class="file-category-title">Nom</div>
                      </div>
                      <div id="upload-file-component">
                        <div
                          class="files-to-upload"
                          v-for="(file,
                          index) in configurationClientToTransfertClient.files"
                          :key="'file' + index"
                        >
                          <p class="file-name">
                            <font-awesome-icon
                              icon="paperclip"
                              class="file-upload-icon"
                            />
                            {{ file.name }}
                          </p>
                          <b-form-group
                            label="Nom fichier"
                            label-for="name_fichier"
                            class="input-modal-champ mb-0"
                          >
                            <b-form-input
                              autocomplete="off"
                              id="name_fichier"
                              v-model="
                                configurationClientToTransfertClient.files[
                                  index
                                ].name_file
                              "
                              required
                              v-on:keydown.13="my_method($event)"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="actionModel">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style mt-3">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <!-- END TRANSFERT CLIENT VERS LA GESTIONS DES SOCIETES MODAL -->
  </div>
</template>

<script>
import '@kouts/vue-modal/dist/vue-modal.css';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import InputColorPicker from 'vue-native-color-picker';

export default {
  name: 'gestion-client-passage',
  data() {
    return {
      type: 'passage',
      searchValue: null,
      error: null,
      loading: false,
      loadingClient: false,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 200, text: 200 }
      ],
      configurationClientToAdd: {
        name: null,
        siren_tva: 'Numéro TVA',
        siren_value: null,
        tva_value: null,
        adresse: null,
        code_postal: null,
        pays: null,
        email: null,
        siret: null,
        telephone: null,
        telephone_portable: null,
        description: null,
        ville: null,
        type: 'passage'
      },
      configurationClientToUpdate: null,
      oldConfigurationClientToUpdate: null,
      configurationClientToDeleted: null,
      configurationClientToTransfertClient: null,
      num_tva_siren: ['Numéro TVA', 'SIREN'],
      uniqueArrayOfCountries: [],
      month_list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      optionsTypeSociete: [
        { text: 'Professionnel', value: 'professionnel' },
        { text: 'Particulier', value: 'particulier' },
        { text: 'Organisme', value: 'organisme' },
        { text: 'SCI', value: 'sci' },
        { text: 'Master Filiale', value: 'master filiale' }
      ],
      mails: []
    };
  },
  methods: {
    ...mapActions([
      'fetchAllTableClient',
      'addNewClientPassage',
      'deleteClientPassage',
      'updateClientPassage',
      'getAllCountreies',
      'getAllTypeSocieteSupport',
      'fetchAllTypeSociete',
      'getAllConditionsPaiement',
      'getAllTypesReglement',
      'fetchDepotsTh',
      'fetchResponsableFilialeFreeTh',
      'getAllMasterFilials',
      'addDepotToFilialeAction',
      'errorManagerFiliale'
    ]),
    async checkFilialeMail(email) {
      const response = await this.errorManagerFiliale({ email: email });
      if (response.success) {
        this.mails = response.response;
      }
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = () => {
          this.configurationClientToTransfertClient.imageData = input.files[0];
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    disabledStartDate(date, index) {
      return (
        this.configurationClientToTransfertClient?.masterFiliales[index + 1]
          ?.date_fin &&
        date <
          new Date(
            this.configurationClientToTransfertClient?.masterFiliales[
              index + 1
            ]?.date_fin
          )
      );
    },
    disabledEndDate(date, index) {
      return (
        this.configurationClientToTransfertClient?.masterFiliales[index]
          ?.date_debut &&
        date <
          new Date(
            this.configurationClientToTransfertClient?.masterFiliales[
              index
            ]?.date_debut
          )
      );
    },
    addNewMasterFilial(type) {
      if (type === 'add') {
        this.configurationClientToTransfertClient?.masterFiliales?.unshift({
          master_id: null,
          date_debut: null,
          date_fin: null
        });
      }
    },
    deleteMasterFilial(index, type) {
      if (type === 'add') {
        this.configurationClientToTransfertClient.masterFiliales.splice(
          index,
          1
        );
      }
    },
    onChangeSupport(checked) {
      if (
        checked === true &&
        this.configurationClientToTransfertClient.responsables &&
        this.configurationClientToTransfertClient.responsables.length
      ) {
        this.configurationClientToTransfertClient.responsables = this.configurationClientToTransfertClient.responsables.filter(
          item => item.type === 'responsable_support'
        );
      }
    },
    showTransfertClient(item) {
      this.getAllTypeSocieteSupport();
      this.fetchAllTypeSociete();
      this.getAllConditionsPaiement();
      this.getAllTypesReglement();
      this.fetchDepotsTh('no');
      this.fetchResponsableFilialeFreeTh();
      this.getAllMasterFilials();
      this.configurationClientToTransfertClient = { ...item };
      this.configurationClientToTransfertClient.type = 'professionnel';
      this.$refs['transfetClientModal'].show();
    },
    async handleSubmitTranssfertClient() {
      this.error = null;
      let ArrayOfStartDate = this.configurationClientToTransfertClient.masterFiliales.filter(
        item => item.date_debut === null
      );
      if (ArrayOfStartDate.length) {
        this.error = 'Date début des masters filiales obligatoire';
        return;
      }
      this.loading = true;
      const response = await this.addDepotToFilialeAction({
        filiale: this.configurationClientToTransfertClient,
        depot: [],
        dejaVolant: false
      });
      if (response.success) {
        this.error = null;
        this.loading = false;
        this.handleFilter();
        this.hideModal('transfetClientModal');
        await this.fetchDepotsTh('no');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    showUpdateClient(item) {
      this.configurationClientToUpdate = { ...item };
      this.oldConfigurationClientToUpdate = item;
      this.$refs['updateClientModal'].show();
    },
    showDeleteClient(item) {
      this.configurationClientToDeleted = item;
      this.$refs['deletedClientModal'].show();
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    changePerPage() {
      this.handleFilter();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    setLocalStorageParamsGestionClientPassage() {
      localStorage.setItem(
        'Params-gestion-client-passage',
        JSON.stringify({
          type: this.type,
          search: this.searchValue
        })
      );
    },
    async handleFilter() {
      await this.fetchAllTableClient({
        per_page: this.perPage,
        page: this.page,
        type: this.type,
        search: this.searchValue
      });
    },
    async changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
      this.setLocalStorageParamsGestionClientPassage();
    },
    resetModal() {
      this.configurationClientToAdd = {
        name: null,
        siren_tva: 'Numéro TVA',
        siren_value: null,
        tva_value: null,
        adresse: null,
        code_postal: null,
        pays: null,
        email: null,
        siret: null,
        telephone: null,
        telephone_portable: null,
        description: null,
        ville: null,
        type: this.type
      };
      this.configurationClientToUpdate = null;
      this.configurationClientToDeleted = null;
      this.configurationClientToTransfertClient = null;
      this.error = null;
      this.mails = [];
    },
    handleSubmitClient() {
      this.loading = true;
      this.addNewClientPassage(this.configurationClientToAdd)
        .then(response => {
          if (response && response.error) {
            this.error = response.error;
            this.loading = false;
          }
          if (response.success) {
            this.error = null;
            this.loading = false;
            this.hideModal('addClientModal');
          }
        })
        .catch();
    },
    async handleUpdateClient() {
      this.loading = true;
      const response = await this.updateClientPassage({
        oldConfigurationClientToUpdate: this.oldConfigurationClientToUpdate,
        configurationClientToUpdate: this.configurationClientToUpdate
      });
      if (response.success) {
        this.error = null;
        this.loading = false;
        this.hideModal('updateClientModal');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    async handleDeleteConfClient() {
      this.loading = true;
      const response = await this.deleteClientPassage(
        this.configurationClientToDeleted
      );
      if (response) {
        this.loading = false;
        this.hideModal('deletedClientModal');
      }
    }
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'getListClientPassages',
      'getClientIsError',
      'getClientlsLoading',
      'getTypeSociete',
      'getPaysList',
      'getTotalRowsPassages',
      'TypeReglement',
      'ConditionPaiement',
      'getResponsableFilialeFreeTh',
      'getTypeSocieteSupportTh',
      'getDepotsTh',
      'getMasterFilialsList'
    ]),
    computedgetMasterFilialsList() {
      let newListMasterFilials = [];
      newListMasterFilials = this.getMasterFilialsList.map(item => {
        return { name: item.name, master_id: item.id };
      });
      return newListMasterFilials;
    },
    computedGetDepotsTh() {
      return this.getDepotsTh;
    },
    computedChangeResponsable() {
      return this.getResponsableFilialeFreeTh.map(item => {
        return { ...item, name: item.full_name };
      });
      // if (this.filialeToAdd.is_societe_support === false) {
      //   return this.getResponsableFilialeFreeTh.map(item => {
      //     return { ...item, name: item.full_name };
      //   });
      // } else {
      //   return this.getResponsableFilialeFreeTh
      //     .map(item => {
      //       return { ...item, name: item.full_name };
      //     })
      //     .filter(item => item.type === 'responsable_support');
      // }
    },
    computedGetLastDateInList() {
      return (
        this.configurationClientToTransfertClient &&
        this.configurationClientToTransfertClient.masterFiliales &&
        this.configurationClientToTransfertClient.masterFiliales[0] &&
        this.configurationClientToTransfertClient.masterFiliales[0].date_fin
      );
    },
    computedConditionPaiement() {
      var table = this.ConditionPaiement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
      return table;
    },
    computedTypeReglement() {
      var table = this.TypeReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
      return [{ text: '', value: null }, ...table];
    },
    requiredP() {
      return function(data) {
        return data && data.type == 'particulier' ? '' : '*';
      };
    },
    computedListeYear: function() {
      var courantYear = moment().year();
      let year = [];
      for (var i = 2019; i <= courantYear + 1; i++) {
        year.push({ value: i, text: i });
      }
      return year;
    },
    computedDaysOfMonth() {
      return function(month) {
        var result = [];
        if (month != null) {
          var courantYear = moment().year();
          var resultday = moment(
            courantYear + '-' + month,
            'YYYY-MM'
          ).daysInMonth();
          result = [...Array(resultday - 1 + 1).keys()].map(x => x + 1);
        }
        return result;
      };
    },
    computedFields() {
      return [
        {
          key: 'name',
          label: 'Nom'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'adresse',
          label: 'Adresse'
        },
        {
          key: 'code_postal',
          label: 'Code postal'
        },
        {
          key: 'pays',
          label: 'Pays'
        },
        {
          key: 'ville',
          label: 'ville'
        },
        {
          key: 'telephone_portable',
          label: 'Téléphone portable'
        },
        {
          key: 'Actions',
          label: 'Actions'
        }
      ];
    }
  },
  components: {
    Card: () => import('../component/card.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    'v-input-colorpicker': InputColorPicker
  },

  async mounted() {
    this.handleFilter();
    await this.getAllCountreies();
    let countryNames = [];
    if (this.getPaysList && this.getPaysList.length) {
      this.getPaysList?.map(element => countryNames.push(element.nom));
      this.uniqueArrayOfCountries = [...new Set(countryNames)];
    }
  }
};
</script>
<style lang="scss">
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Parcourir...';
  background-color: #4d4bac;
  color: white;
  height: 37px;
}

.custom-file-input:lang(en) ~ .custom-file-label {
  border: none;
  border-radius: 20px;
  background-color: #fff;

  span {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 500;
    pointer-events: none;
    margin-top: 5px;
  }
}

.cutsom-modal-bootstrap-transfertClient {
  .modal-dialog {
    top: 0%;
    max-width: 70%;
  }
}
.cutsom-modal-bootstrap-filiale-th {
  .modal-dialog {
    top: 0%;
    max-width: 50%;
  }
}
.setting-client-passage {
  .custom-table-style {
    .thead-light {
      th {
        padding: 3px 5px;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.custom-table-passage {
  max-height: 200px !important;
  height: 200px !important;
}
.custem-popover-passage {
  min-width: 100% !important;
}
#transfetClientModal {
  .justify-space-around {
    justify-content: space-around;
  }
  .time-icon-style {
    margin-top: 33px;
    color: red;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .rib_block {
    color: #000;
    position: relative;
    overflow: visible;
    font-size: 12px;
    margin-top: 5px;
    border: 1px solid rgba(108, 117, 125, 0.3215686275);
    padding: 4px;
    background-color: #f5f4fa;
    width: 99%;
    border-radius: 4px;
    margin: 0px auto;

    .button-add-prop-filiale-th {
      display: flex;
    }

    .edit-action {
      width: 5%;
      display: flex;
      font-size: 7px;
      justify-content: space-between;
      height: 80px;
      align-content: center;
      align-items: center;
      margin-right: 2%;

      .action-icon {
        cursor: pointer;
      }

      .validate {
        color: green;
      }

      .cancel {
        color: #ab2525;
      }
    }
  }

  .input-modal-champ div input {
    height: 30px;
    border-color: #70707038;
    border-radius: 17px;
    width: inherit;
  }
  .button-add-prop-filiale-th {
    display: flex;
  }
  .box-upload {
    background-color: #ffffff;
    border-radius: 25px;
    width: 100%;
    position: relative;
    margin-right: 14px;
    padding: 0;
    color: #26292c;
  }

  .form-type {
    align-items: center;
    align-content: center;
    justify-content: center;
    min-width: 56%;
    margin: auto;

    .form-groupe {
      .file-header-titles {
        align-items: center;
        padding: 4px 20px;
        display: flex;

        .file-name-title,
        .file-category-title {
          text-align: center;
          color: #495057;
          font-weight: 600;
          border-radius: 6px;
          // background-color: #8d8cb7;
          padding: 3px;
        }

        .file-name-title {
          width: 60%;
          margin-right: 10px;
        }

        .file-category-title {
          width: 40%;
        }
      }

      .files-to-upload {
        display: flex;
        align-items: center;
        padding: 2px 20px;

        .file-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 60%;
          margin-right: 10px;
          text-align: left;
          font-size: 0.6rem;

          .file-upload-icon {
            color: #2dabe2;
          }
        }

        .file-category {
          width: 40%;
          font-size: 13px;
          border-radius: 17px;
        }
      }

      .actionFile {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 5px;

        .button-cancel-style {
          background-color: #49505782;
          color: white;
          border: none;
        }

        .chargement {
          margin-left: 5px;

          .spinner-border {
            width: 1rem;
            height: 1rem;
          }
        }
      }

      .error {
        color: red;
        font-size: 14px;
        text-align: center;
      }
    }
  }
  .doc-list {
    .files-tabs {
      .nav-tabs {
        justify-content: center;
        background-color: #fff;
        padding: 5px 0;
        border: none;
        border-radius: 49px;
        width: 57%;
        margin: auto;
        margin-bottom: 15px;

        .files-tab-title {
          position: relative;
          font-size: 12px;
          color: #404346;
          background-color: transparent;
          border: 1px solid #d5d5d5;
          border-radius: 20px;
          padding: 0px 10px;
          padding: 2px 11px;
          cursor: pointer;
          user-select: none;
          transition: all 0.4s ease;
          border: none;
          margin-right: 8px;

          .counter {
            color: #858585;
            margin-left: 5px;
          }

          &.active {
            color: white !important;
            border-radius: 20px;
            border: none;
            background-color: #9799d6 !important;
            padding: 2px 11px;

            .counter {
              color: #2dabe2;
            }
          }
        }
      }
    }
  }
  .body-box-setting {
    display: flex;
    background-color: #f5f4fa;
    height: auto;
    margin-top: 3px;
    border-radius: 28px;
    position: relative;
    padding: 11px;
  }
}
.table-passages-params {
  max-height: calc(100vh - 280px) !important;
  height: calc(100vh - 280px) !important;
  margin-bottom: 0px;
  .button-danger-style {
    background-color: #dc3c3c;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    padding: 6px 15px;
    border: none;
  }
  .button-warning-style {
    background-color: #ffc107;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    // font-weight: 600;
    color: #ffffff;
    // border-radius: 25px;
    // padding: 6px 11px;
    border: none;
  }
  .button-duplicate-style {
    background-color: #17a2b8;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    // font-weight: 600;
    color: #ffffff;
    // border-radius: 25px;
    // padding: 6px 15px;
    border: none;
  }
  .button-modifier-style {
    background-color: #699d86;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    // font-weight: 600;
    color: #ffffff;
    // border-radius: 25px;
    // padding: 6px 15px;
    border: none;
  }
}
.setting-client-passage {
  .custom-table-style {
    .thead-light {
      th {
        padding: 3px 5px;
      }
    }
  }
  .actions {
    position: sticky;
    right: -1px !important;
  }
  .button-warning-style {
    background-color: #dc3c3c;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    padding: 6px 11px;
    border: none;
    &:hover {
      background-color: #dd0b0b;
    }
  }
  .button-update-style {
    background-color: #699d86;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    padding: 6px 11px;
    border: none;
    &:hover {
      background-color: #1e7e34;
    }
  }
  .button-transfert-style {
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    background-color: #8d8cb7;
    border-radius: 25px;
    padding: 6px 10px;
    border: none;
    &:hover {
      background-color: #4d4bac;
    }
  }
}
#deletedClientModal {
  .fontBold {
    font-weight: bold;
  }
}
#addClientModal {
  .more_less {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color: #428bca;
    font-size: 13px;
    float: right;
    width: 100px;
    margin: 0;
  }
  .more_less:hover {
    text-decoration: underline;
  }
}
</style>
